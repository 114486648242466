import React, { Component, Fragment } from 'react';
import { SingleLessonActions, SingleLessonPaths } from './SingleLessonConstans';

import { PropsRoute } from '../common/route/PropsRoute';
import { SingleLesson } from './SingleLesson';

export default class SingleLessonRoutes extends Component {
  render() {
    return (
      <Fragment>
        <PropsRoute
          path={SingleLessonPaths.List}
          component={SingleLesson}
          action={SingleLessonActions.List}
        />
        <PropsRoute
          path={SingleLessonPaths.EditRouter}
          component={SingleLesson}
          action={SingleLessonActions.Edit}
        />
        <PropsRoute
          path={SingleLessonPaths.CloneRouter}
          component={SingleLesson}
          action={SingleLessonActions.Clone}
        />
        <PropsRoute
          path={SingleLessonPaths.Create}
          component={SingleLesson}
          action={SingleLessonActions.Create}
        />
      </Fragment>
    );
  }
}

import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import {
  FormControl,
  Grid,
  MenuItem,
  Select,
  Typography
} from '@material-ui/core';
import authService from '../../api-authorization/AuthorizeService';
import { Loading } from '../../ui/Loading';
import { stylesCharting } from '../../class-course/reports/stylesCharting';
import { stylesAcademicInfo } from '../../academic-manage/stylesAcademicInfo';
import { TeacherPieChartView } from './TeacherPieChartView';
import { ClassLessonStatistics } from './ClassLessonStatistics';
import { NumberView } from './NumberView';
import { TeacherLessonChart } from './TeacherLessonChart';
import { TeacherChartView } from './TeacherChartView';

class StudentStatistics extends Component {
  constructor(...args) {
    super(...args);
    this.state = {
      selectedYear: '',
      loading: true
    };
  }

  componentDidMount() {
    this.populateTeacherData();
  }

  populateTeacherData = async () => {
    const { teacherInfo } = this.props;
    const token = await authService.getAccessToken();

    const [
      respTeacherData,
      respStudentResults,
      respClassesStatistics
    ] = await Promise.all([
      fetch(`api/TeacherReport/GetTeacherStatistics/${teacherInfo.id}`, {
        headers: !token ? {} : { Authorization: `Bearer ${token}` }
      }),
      fetch(`api/TeacherReport/GetStudentResults/${teacherInfo.id}`, {
        headers: !token ? {} : { Authorization: `Bearer ${token}` }
      }),
      fetch(`api/TeacherReport/GetClassLessonStatistics/${teacherInfo.id}`, {
        headers: !token ? {} : { Authorization: `Bearer ${token}` }
      })
    ]);
    const [teacherData, studentResults, classesStatistics] = await Promise.all([
      respTeacherData.json(),
      respStudentResults.json(),
      respClassesStatistics.json()
    ]);

    const renewRatioData = [
      {
        key: 'Renew Ratio',
        data: teacherData.renewStudentsRatio
      },
      {
        key: '',
        data: 100 - teacherData.renewStudentsRatio
      }
    ];

    const chart1Categories = [
      'Thứ 2',
      'Thứ 3',
      'Thứ 4',
      'Thứ 5',
      'Thứ 6',
      'Thứ 7',
      'CN'
    ];
    const attendingInWeekArray = Object.values(teacherData.attendingInWeek);

    const chart1SeriesDataLessThan8 = attendingInWeekArray.map(
      day => day.item1
    );
    const chart1SeriesDataMoreThan8 = attendingInWeekArray.map(
      day => day.item2
    );

    const chart2Categories = Object.keys(teacherData.renewMainTeachersByMonth);
    const chart3Categories = Object.keys(teacherData.renewCoTeachersByMonth);
    const allMonths = [
      ...new Set([...chart2Categories, ...chart3Categories])
    ].sort((a, b) => {
      const [monthA, yearA] = a.split('/').map(Number);
      const [monthB, yearB] = b.split('/').map(Number);
      return yearA !== yearB ? yearA - yearB : monthA - monthB;
    });

    const uniqueYearsFromAllMonths = allMonths
      .map(month => parseInt(month.split('/')[1], 10))
      .sort((a, b) => a - b);

    const chart2SeriesData = allMonths.map(month => {
      return teacherData.renewMainTeachersByMonth[month] || 0;
    });

    const chart3SeriesData = allMonths.map(month => {
      return teacherData.renewCoTeachersByMonth[month] || 0;
    });

    const classCategories = studentResults.classNames;
    const averageSeriesData = studentResults.averageRatio;
    const fairSeriesData = studentResults.fairRatio;
    const goodSeriesData = studentResults.goodRatio;
    const exellentSeriesData = studentResults.exellentRatio;

    const classesStatisticsCategories = classesStatistics.classNames;
    const classesStatisticsLateSeriesData = classesStatistics.lateRatio;
    const classesStatisticsEarlySeriesData = classesStatistics.earlyRatio;

    this.setState({
      teacherData,
      renewRatioData,
      chart1Categories,
      chart1SeriesDataLessThan8,
      chart1SeriesDataMoreThan8,
      chart3SeriesData,
      allMonths,
      uniqueYearsFromAllMonths,
      chart2SeriesData,
      classCategories,
      averageSeriesData,
      fairSeriesData,
      goodSeriesData,
      exellentSeriesData,
      classesStatisticsCategories,
      classesStatisticsLateSeriesData,
      classesStatisticsEarlySeriesData,
      loading: false
    });
  };

  render() {
    const { loading } = this.state;
    let content = loading ? <Loading /> : this.renderUI();

    return <Fragment>{content}</Fragment>;
  }

  handleYearChange = event => {
    this.setState({ selectedYear: event.target.value });
  };

  renderUI() {
    const { teacherClasses, classes } = this.props;
    const {
      teacherData,
      renewRatioData,
      chart1Categories,
      chart1SeriesDataLessThan8,
      chart1SeriesDataMoreThan8,
      chart3SeriesData,
      allMonths,
      uniqueYearsFromAllMonths,
      selectedYear,
      chart2SeriesData,
      classCategories,
      averageSeriesData,
      fairSeriesData,
      goodSeriesData,
      exellentSeriesData,
      classesStatisticsCategories,
      classesStatisticsLateSeriesData,
      classesStatisticsEarlySeriesData
    } = this.state;

    const chartOptions = {
      chart: {
        type: 'bar',
        toolbar: {
          show: false
        },
        zoom: {
          enabled: false
        }
      },
      dataLabels: {
        enabled: false
      }
    };

    const chart1Options = {
      ...chartOptions,
      chart: {
        ...chartOptions.chart,
        stacked: true
      },
      xaxis: {
        categories: chart1Categories
      },
      stroke: {
        show: false
      },
      colors: ['#6fa1f3', '#f25f28']
    };

    const chart1Series = [
      {
        name: 'Students',
        data: chart1SeriesDataMoreThan8,
        value: allMonths
      },
      {
        name: 'Students ReNew < 8b',
        data: chart1SeriesDataLessThan8,
        value: allMonths
      }
    ];
    const filteredMonths = selectedYear
      ? allMonths.filter(month => month.split('/')[1] === String(selectedYear))
      : allMonths;

    const teachersChartOptions = {
      ...chartOptions,
      chart: {
        ...chartOptions.chart,
        stacked: true
      },
      xaxis: {
        categories: filteredMonths
      },
      stroke: {
        show: false
      },
      colors: ['#f2a129', '#6fa1f3']
    };
    const teachersChartSeries = [
      {
        name: 'Main Teacher',
        data: chart2SeriesData
      },
      {
        name: 'Co Teacher',
        data: chart3SeriesData
      }
    ];

    const classResultChartOptions = {
      ...chartOptions,
      chart: {
        ...chartOptions.chart,
        stacked: true
      },
      xaxis: {
        categories: classCategories
      },
      stroke: {
        show: false
      },
      colors: ['#f2da29', '#f2a129', '#f25f28', '#6fa1f3']
    };
    const classResultChartSeries = [
      {
        name: 'HS loại Average',
        data: averageSeriesData
      },
      {
        name: 'HS loại Fair',
        data: fairSeriesData
      },
      {
        name: 'HS loại Good',
        data: goodSeriesData
      },
      {
        name: 'HS loại Exellent',
        data: exellentSeriesData
      }
    ];

    const classesStatisticsChartOptions = {
      ...chartOptions,
      xaxis: {
        categories: classesStatisticsCategories
      },
      stroke: {
        show: true,
        width: 5,
        colors: ['transparent']
      },
      colors: ['#f2a129', '#6fa1f3']
    };
    const classesStatisticsChartSeries = [
      {
        name: '% Buổi dạy trễ tiến độ',
        data: classesStatisticsLateSeriesData
      },
      {
        name: '% Buổi dạy sớm tiến độ',
        data: classesStatisticsEarlySeriesData
      }
    ];
    const filteredChartSeries = selectedYear
      ? teachersChartSeries.map(series => ({
          ...series,
          data: series.data.filter(
            (_, index) => uniqueYearsFromAllMonths[index] === selectedYear
          )
        }))
      : teachersChartSeries;

    return (
      <Fragment>
        <Grid container className={classes.stdHeadline}>
          <div className={classes.stdSubtitle}>
            <Typography variant="h3" className={classes.sectionTitle}>
              {'Thông tin thống kê & báo cáo của lớp học về học sinh'}
            </Typography>
          </div>
          <div
            style={{ marginTop: '20px', paddingRight: '20px' }}
            className={classes.stdSubtitleHr}
          >
            <span className={clsx(classes.stdLineCell)}></span>
          </div>
          <div className={classes.stdSubtitle} style={{ marginLeft: 'auto' }}>
            <Typography variant="body1" className={classes.sectionTitle}>
              {teacherData.today
                ? `${new Date(teacherData.today).toLocaleDateString()}`
                : 'Loading...'}
            </Typography>
          </div>
        </Grid>

        <Grid container className={classes.stdBox} spacing={2}>
          <Grid item xs={5} container direction="column" justify="flex-start">
            <div style={{ maxWidth: '100%' }}>
              <div className={clsx(classes.chartBoxTopPad)}>
                <Grid container>
                  <Grid item xs={6}>
                    <TeacherPieChartView
                      title="Total NoS"
                      subtitle="Tổng số học sinh"
                      chartTitle="Tỷ lệ phần trăm số học sinh renew"
                      chartData={renewRatioData}
                      numberValue={teacherData.currentStudents}
                      classes={classes}
                    />
                  </Grid>
                  <Grid item xs={6} className={classes.chartBoxLeftPad}>
                    <ClassLessonStatistics
                      title1="Total Classes"
                      subtitle1="Tổng số lớp đã dạy"
                      title2="Total Lessons"
                      subtitle2="Tổng số buổi đã dạy"
                      numberValue1={teacherData.totalClasses}
                      numberValue2={teacherData.totalLessons}
                      numberTitle="Số buổi dạy demo"
                      numberValue={teacherData.demoSessions}
                      classes={classes}
                    />
                  </Grid>
                </Grid>
              </div>
              <div className={clsx(classes.chartBoxTopPad)}>
                <Grid container>
                  <Grid item xs={4}>
                    <NumberView
                      title="Catch-up Sessions"
                      subtitle="Số buổi dạy catch-up"
                      numberValue={teacherData.catchUpSessions}
                      numberColor={classes.chartLabelLightBlue}
                      classes={classes}
                    />
                  </Grid>
                  <Grid item xs={4} className={classes.chartBoxLeftPad}>
                    <NumberView
                      title="Renew Main Teachers"
                      subtitle="Số giáo viên chính renew"
                      numberValue={teacherData.renewMainTeachers}
                      numberColor={classes.chartLabelOrange}
                      classes={classes}
                    />
                  </Grid>
                  <Grid item xs={4} className={classes.chartBoxLeftPad}>
                    <NumberView
                      title="Renew Co-Teachers"
                      subtitle="Số giáo viên hỗ trợ renew"
                      numberValue={teacherData.renewCoTeachers}
                      numberColor={classes.chartLabelLightBlue}
                      classes={classes}
                    />
                  </Grid>
                </Grid>
              </div>
              <div className={clsx(classes.chartBoxTopPad)}>
                <Grid container>
                  <Grid item xs={12}>
                    <TeacherLessonChart
                      title="Lesson Time progress"
                      subtitle="Thời gian giảng dạy của giáo viên với từng lớp"
                      classList={teacherClasses}
                      classes={classes}
                    />
                  </Grid>
                </Grid>
              </div>
            </div>
          </Grid>

          <Grid item xs={7} container direction="column" justify="flex-start">
            <div className={clsx(classes.chartBoxRoot, classes.chartBoxTopPad)}>
              <Grid item xs={12}>
                <TeacherChartView
                  title="Number of Students Attending"
                  subtitle="Sĩ số học sinh giáo viên phụ trách"
                  chartOptions={chart1Options}
                  chartSeries={chart1Series}
                  classes={classes}
                />
              </Grid>
            </div>
            <div className={clsx(classes.chartBoxRoot, classes.chartBoxTopPad)}>
              <Grid item xs={12} style={{ position: 'relative' }}>
                <FormControl style={{ position: 'absolute', right: '1rem' }}>
                  <Select
                    style={{ width: 200 }}
                    value={selectedYear}
                    onChange={this.handleYearChange}
                  >
                    <MenuItem>
                      <em>Chọn năm</em>
                    </MenuItem>
                    {Array.from(new Set(uniqueYearsFromAllMonths)).map(year => (
                      <MenuItem key={year} value={year}>
                        {year}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
                <TeacherChartView
                  title="Number of Students Renew"
                  subtitle="Biểu đồ tổng số giáo viên renew theo tháng"
                  chartOptions={teachersChartOptions}
                  chartSeries={filteredChartSeries}
                  classes={classes}
                />
              </Grid>
            </div>
            <div className={clsx(classes.chartBoxRoot, classes.chartBoxTopPad)}>
              <Grid item xs={12}>
                <TeacherChartView
                  title="Percentage Results of Student with Class is over"
                  subtitle="Biểu đồ phân loại học sinh của lớp đã kết thúc của giáo viên"
                  chartOptions={classResultChartOptions}
                  chartSeries={classResultChartSeries}
                  classes={classes}
                />
              </Grid>
            </div>
            <div className={clsx(classes.chartBoxRoot, classes.chartBoxTopPad)}>
              <Grid item xs={12}>
                <TeacherChartView
                  title="Percentage Lesson Progress Late/Early in Let's Learn"
                  subtitle="Biểu đồ tỷ lệ hiệu suất giảng dạy theo tiến độ bài giảng trong Let's Learn"
                  chartOptions={classesStatisticsChartOptions}
                  chartSeries={classesStatisticsChartSeries}
                  classes={classes}
                />
              </Grid>
            </div>
          </Grid>
        </Grid>
      </Fragment>
    );
  }
}

const stylesLessonStatistics = theme => ({
  ...stylesCharting(theme),
  ...stylesAcademicInfo(theme)
});

StudentStatistics.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(stylesLessonStatistics)(StudentStatistics);

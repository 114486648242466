import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { fetchHelper } from './../../../helpers/fetch-helper';
import {
  Select,
  MenuItem,
  FormControl,
  FormControlLabel,
  Checkbox
} from '@material-ui/core';

const WorkLogResponsible = props => {
  const [existEvent, setExistEvent] = useState(false);
  const {
    handleChange,
    options,
    teacher,
    workLog,
    disabled,
    eventChange
  } = props;

  useEffect(() => {
    fetchHelper
      .get(`api/worklog/check-exist-event/${workLog.teacherLessonLogId}`)
      .then(setExistEvent);
  }, [workLog]);

  const onChange = e => {
    const value = e.target.value;
    handleChange({
      ...workLog,
      [e.target.name]: value,
      score: options.find(x => x.value === value)?.score
    });
  };

  const onEventChange = e => {
    const value = e.target.checked;
    eventChange({
      ...workLog,
      [e.target.name]: value
    });
  };

  return (
    <div
      style={{
        display: 'flex',
        gap: '2rem'
      }}
    >
      <FormControl>
        <FormControlLabel
          labelPlacement="start"
          style={{ width: 500, justifyContent: 'space-between' }}
          control={
            <Select
              style={{ minWidth: 230 }}
              name="effortType"
              value={workLog?.effortType}
              onChange={onChange}
              disabled={disabled}
            >
              {options
                .filter(x =>
                  teacher?.isPrimary === undefined
                    ? true
                    : teacher.isPrimary &&
                      !['co_teacher'].includes(workLog?.effortType)
                    ? !['co_teacher'].includes(x.value)
                    : !['main_full', 'main_teacher'].includes(x.value)
                )
                ?.map(e => (
                  <MenuItem value={e.value} key={e.value}>
                    {e.label}
                  </MenuItem>
                ))}
            </Select>
          }
          label={teacher?.alias || teacher?.fullName}
        />
      </FormControl>

      {existEvent && workLog.effortType != 'off' && (
        <FormControlLabel
          control={
            <Checkbox
              name="isEvent"
              checked={workLog.isEvent}
              onChange={onEventChange}
            />
          }
          label={'Event'}
          disabled={disabled}
        />
      )}
    </div>
  );
};

WorkLogResponsible.propTypes = {
  workLog: PropTypes.object,
  options: PropTypes.array,
  handleChange: PropTypes.func,
  teacher: PropTypes.object,
  disabled: PropTypes.bool,
  eventChange: PropTypes.func
};

export default WorkLogResponsible;

import React, { Component, Fragment } from 'react';
import { Link, Redirect } from 'react-router-dom';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import {
  Checkbox,
  Fade,
  FormControlLabel,
  Icon,
  Paper,
  Table,
  TableBody,
  TableHead,
  TableRow,
  Typography
} from '@material-ui/core';
import { red } from '@material-ui/core/colors';
import { faNewspaper } from '@fortawesome/free-solid-svg-icons';
import { ActionIconButton, NormalTooltip } from '../ui/ButtonStyles';
import { CustomTableCell } from '../ui/table/CommonDataTable';
import MyClassLessonLog from './MyClassLessonLog';
import MyClassStudentLog from './MyClassStudentLog';
import AcademicAnnounce from './views/AcademicAnnounce';
import { MyClassFilterToolbar } from './MyClassFilterToolbar';
import { CatchUpSchedulePaths } from '../catch-up/CatchUpScheduleContans';
import { MyClassPaths } from './MyClassConstants';
import { EventPaths } from './event/EventConstants';
import { StoryPaths } from '../story/StoryConstants';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import DescriptionOutlinedIcon from '@material-ui/icons/DescriptionOutlined';
import AssignmentIndIcon from '@material-ui/icons/AssignmentInd';
import AssessmentIcon from '@material-ui/icons/Assessment';
import AttendanceDialog from '../attendance/AttendanceDialog';
import authService from '../api-authorization/AuthorizeService';

const stylesMyClassPresentTable = theme => ({
  root: {
    //marginTop: theme.spacing(1),
  },
  table: {
    minWidth: 700
  },
  col: {
    // maxWidth: 180
  },
  actionCol: {
    width: 150,
    minWidth: 150,
    maxWidth: 300
  },
  actionPanel: {
    display: 'inline-block',
    whiteSpace: 'nowrap'
  },
  actionIcon: {
    fontSize: 20
  },
  actionIconFa: {
    height: 20
  },
  materialFName: {
    maxWidth: 250,
    width: 250
  },
  activePrintWorksheet: {
    color: red[500]
  }
});

class MyClassPresentTable extends Component {
  constructor(...args) {
    super(...args);
    this.lessonReportDialog = React.createRef();
    this.classStudentReportDialog = React.createRef();
    this.academicAnnounceDialog = React.createRef();
    this.timerAlertPrintWorksheet = null;
    this.attendanceDialog = React.createRef();
    this.state = {
      data: this.props.rows,
      isMySchedule: this.props.isMySchedule,
      redirect: null,
      filterText: '',
      checkField: false,
      classLessonId: null,
      classId: null,
      lessonLog: null,
      alertPrintWorksheet: [],
      activePrintWorksheet: [],
      lessonReportDialog: this.props.previewMode ? null : (
        <MyClassLessonLog
          onRef={actualChild => (this.lessonReportDialog = actualChild)}
        />
      ),
      classStudentReportDialog: this.props.previewMode ? null : (
        <MyClassStudentLog
          onRef={actualChild => (this.classStudentReportDialog = actualChild)}
        />
      ),
      academicAnnounceDialog: (
        <AcademicAnnounce
          onRef={actualChild => (this.academicAnnounceDialog = actualChild)}
        />
      )
    };
  }

  componentDidMount() {
    if (!this.props.previewMode) {
      let alertPrintWorksheet = new Array(this.props.rows.length);
      let activePrintWorksheet = new Array(this.props.rows.length);
      alertPrintWorksheet.fill(true);
      activePrintWorksheet.fill(false);
      this.setState({ alertPrintWorksheet, activePrintWorksheet });
      this.startTimer();
    }
  }
  componentWillUnmount() {
    this.stopTimer();
  }

  startTimer() {
    if (!this.timerAlertPrintWorksheet) {
      this.timerAlertPrintWorksheet = setInterval(() => {
        const nowTime = new Date();
        // 10 mins before starting
        const timeSpan = 10;
        const startTimeTest = new Date(
          nowTime.getTime() + timeSpan * 60 * 1000
        );
        const endTimeTest = new Date(nowTime.getTime() - timeSpan * 60 * 1000);
        let alertPrintWorksheet = this.state.alertPrintWorksheet;
        let activePrintWorksheet = this.state.activePrintWorksheet;
        for (let i = 0; i < this.props.rows.length; i++) {
          const startTime = new Date(this.props.rows[i].startTime);
          if (endTimeTest < startTime && startTime < startTimeTest) {
            alertPrintWorksheet[i] = !alertPrintWorksheet[i];
            activePrintWorksheet[i] = true;
          } else {
            alertPrintWorksheet[i] = true;
            activePrintWorksheet[i] = false;
          }
        }
        this.setState({ alertPrintWorksheet, activePrintWorksheet });
      }, 1000);
    }
  }
  stopTimer() {
    if (this.timerAlertPrintWorksheet) {
      clearInterval(this.timerAlertPrintWorksheet);
      this.timerAlertPrintWorksheet = null;
    }
  }
  printWorksheet = row => {
    let viewUrl = `/api/LessonPlans/GetForView/${row.lessonPlan.id}/${encodeURI(
      row.lessonPlan.worksheetFileName
    )}`;
    window.open(viewUrl, '_blank');
  };

  handleLetsLearn = row => {
    window.open(
      `${MyClassPaths.LetsLearn}/${row.classLessonId}/${row.classId}/0/0`
    );
  };

  openLessonReport = async () => {
    const { classLessonId, lessonLog } = this.state;
    this.lessonReportDialog.openLessonReport(classLessonId, lessonLog);
  };

  handleLessonReport = row => {
    this.setState(
      {
        classLessonId: row.subId,
        classId: row.classId
      },
      this.openLessonReport
    );
  };

  openClassStudentReport = async () => {
    const { classLessonId, lessonLog, classId } = this.state;
    this.classStudentReportDialog.openClassStudentReport(
      classLessonId,
      lessonLog,
      classId
    );
  };

  handleClassStudentReport = row => {
    this.setState(
      {
        classLessonId: row.subId,
        classId: row.classId
      },
      this.openClassStudentReport
    );
  };

  saveLessonLog = async classLessonId => {
    const [token, loggedInUser] = await Promise.all([
      authService.getAccessToken(),
      authService.getUser()
    ]);
    const headers = {
      ...{ Accept: 'application/json', 'Content-Type': 'application/json' },
      ...(!token ? {} : { Authorization: `Bearer ${token}` })
    };
    const lessonLogData = {
      teacherId: loggedInUser.sub,
      classLessonId: classLessonId
    };
    const response = await fetch(`api/TeacherLessonLog/AddTodayLessonLog`, {
      method: 'POST',
      body: JSON.stringify(lessonLogData),
      headers: headers
    });
    const lessonLog = await response.json();
    return lessonLog;
  };

  getLessonLog = async classLessonId => {
    const token = await authService.getAccessToken();
    const headers = !token ? {} : { Authorization: `Bearer ${token}` };
    const response = await fetch(
      `api/TeacherLessonLog/GetLessonLog/${classLessonId}`,
      {
        headers: headers
      }
    );
    const lessonLog =
      response.status === 200
        ? await response.json()
        : await this.saveLessonLog(classLessonId);
    return lessonLog;
  };

  showAttendanceDialog = async (row, dlgType) => {
    const classLessonId = row.subId;
    const classId = row.classId;
    const lessonLog = await this.getLessonLog(classLessonId);
    this.attendanceDialog.openAttendanceDialog(
      dlgType,
      lessonLog.id,
      classId,
      classLessonId
    );
  };

  handleAcademicAnnounce = row => {
    this.academicAnnounceDialog.openAcademicAnnouncement(row.classId || row.id);
  };

  viewCatchUp = row => {
    this.redirectTo(`${CatchUpSchedulePaths.CreateCatchUpLesson}/${row.id}`);
  };

  redirectTo = toUrl => {
    this.setState({
      redirect: <Redirect to={toUrl} push={true} />
    });
  };

  handleChange = name => event => {
    const attrName = name;
    const target = event.target;
    const attrValue =
      target.type === 'checkbox' ? target.checked : target.value;
    this.setState({
      [attrName]: attrValue
    });
  };

  handleChangeCheckbox = event => {
    const checkField = event.target.checked;
    this.setState({ checkField });
    this.props.handleFilters(checkField);
  };

  render() {
    const { classes, rows, cols, checkCondition } = this.props;
    const {
      filterText,
      checkField,
      alertPrintWorksheet,
      activePrintWorksheet
    } = this.state;

    let filteredRows = rows.filter(e => {
      if (filterText === '') return true;
      let getRow = false;
      for (var i = 0; i < cols.length; i++) {
        let col = cols[i];
        getRow =
          col.obj && e[col.name] !== null
            ? e[col.name][col.objName] &&
              e[col.name][col.objName].includes(filterText)
            : e[col.name] && e[col.name].includes(filterText);
        if (getRow) break;
      }
      return getRow;
    });
    return (
      <Paper className={classes.root}>
        {this.state.lessonReportDialog}
        {this.state.classStudentReportDialog}
        {this.state.academicAnnounceDialog}
        <MyClassFilterToolbar
          filterText={this.state.filterText}
          handleChange={this.handleChange}
        />

        {checkCondition && (
          <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={checkField}
                  onChange={this.handleChangeCheckbox}
                />
              }
              label={checkCondition}
            />
          </div>
        )}
        <Table className={classes.table}>
          <TableHead>
            <TableRow>
              {cols.map(col => (
                <CustomTableCell
                  className={classes.col}
                  component="th"
                  scope="row"
                  align={col.align}
                  key={col.name}
                >
                  {col.header}
                </CustomTableCell>
              ))}
              <CustomTableCell className={classes.actionCol} align="center">
                <div className={classes.actionPanel}>&nbsp;</div>
              </CustomTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {filteredRows.map((row, idx) => (
              <TableRow className={classes.row} key={row.id}>
                {cols.map(col => (
                  <CustomTableCell
                    align={col.align}
                    key={row.id + col.name}
                    className={
                      col.className === undefined
                        ? classes.col
                        : clsx(classes.col, classes[col.className])
                    }
                  >
                    <Typography
                      variant="body2"
                      noWrap={col.noWrap === undefined ? undefined : true}
                    >
                      {col.obj && row[col.name] !== null
                        ? row[col.name][col.objName]
                        : row[col.name]}
                    </Typography>
                  </CustomTableCell>
                ))}
                {this.state.isMySchedule ? (
                  <CustomTableCell
                    className={clsx(classes.col, classes.actionCol)}
                    align="center"
                  >
                    <div className={classes.actionPanel}>
                      <NormalTooltip title="Class student report">
                        <ActionIconButton
                          onClick={() => this.handleClassStudentReport(row)}
                        >
                          <Icon className={classes.actionIcon}>assignment</Icon>
                        </ActionIconButton>
                      </NormalTooltip>
                      <NormalTooltip title="Catch-Up">
                        <ActionIconButton onClick={() => this.viewCatchUp(row)}>
                          <Icon className={classes.actionIcon}>restore</Icon>
                        </ActionIconButton>
                      </NormalTooltip>
                    </div>
                  </CustomTableCell>
                ) : (
                  <CustomTableCell
                    className={clsx(classes.col, classes.actionCol)}
                    align="center"
                  >
                    <div className={classes.actionPanel}>
                      {!this.props.previewMode && !row.ended && (
                        <Fragment>
                          <NormalTooltip title="Start my lesson">
                            <ActionIconButton
                              onClick={() => this.handleLetsLearn(row)}
                            >
                              <Icon className={classes.actionIcon}>
                                play_arrow
                              </Icon>
                            </ActionIconButton>
                          </NormalTooltip>
                        </Fragment>
                      )}
                      {!this.props.previewMode &&
                        row.lessonPlan.worksheetFileName && (
                          <NormalTooltip title="View/print worksheet">
                            <Fade in={alertPrintWorksheet[idx]}>
                              <ActionIconButton
                                color={
                                  activePrintWorksheet[idx]
                                    ? 'primary'
                                    : 'default'
                                }
                                onClick={() => this.printWorksheet(row)}
                              >
                                <DescriptionOutlinedIcon
                                  className={clsx(classes.actionIcon, {
                                    [classes.activePrintWorksheet]:
                                      activePrintWorksheet[idx]
                                  })}
                                />
                              </ActionIconButton>
                            </Fade>
                          </NormalTooltip>
                        )}
                      {this.props.previewMode && (
                        <Fragment>
                          <NormalTooltip title="Academic announcement">
                            <ActionIconButton
                              onClick={() => this.handleAcademicAnnounce(row)}
                            >
                              <Icon className={classes.actionIcon}>
                                announcement
                              </Icon>
                            </ActionIconButton>
                          </NormalTooltip>
                          <NormalTooltip title="Events">
                            <ActionIconButton
                              component={Link}
                              to={`${EventPaths.List}/${row.classId || row.id}`}
                            >
                              <Icon className={classes.actionIcon}>
                                event_available
                              </Icon>
                            </ActionIconButton>
                          </NormalTooltip>
                          <NormalTooltip title="News feed">
                            <ActionIconButton
                              component={Link}
                              to={`${StoryPaths.Home}/${row.classId || row.id}`}
                            >
                              <FontAwesomeIcon
                                className={classes.actionIconFa}
                                icon={faNewspaper}
                                size="xs"
                              />
                            </ActionIconButton>
                          </NormalTooltip>
                          <NormalTooltip title="Student list">
                            <ActionIconButton
                              component={Link}
                              to={`${MyClassPaths.StudentView}/${row.classId ||
                                row.id}`}
                            >
                              <Icon className={classes.actionIcon}>
                                assignment_ind
                              </Icon>
                            </ActionIconButton>
                          </NormalTooltip>
                          <NormalTooltip title="Class reports">
                            <ActionIconButton
                              component={Link}
                              to={`${MyClassPaths.ViewReport}/${row.classId ||
                                row.id}`}
                            >
                              <Icon className={classes.actionIcon}>
                                pageview
                              </Icon>
                            </ActionIconButton>
                          </NormalTooltip>
                        </Fragment>
                      )}
                      {!this.props.previewMode && row.ended && (
                        <Fragment>
                          <NormalTooltip title="Student attendance">
                            <ActionIconButton
                              onClick={() =>
                                this.showAttendanceDialog(row, 'attendance')
                              }
                            >
                              <AssignmentIndIcon
                                className={classes.actionIcon}
                              />
                            </ActionIconButton>
                          </NormalTooltip>
                          <NormalTooltip title="Student assessment">
                            <ActionIconButton
                              onClick={() =>
                                this.showAttendanceDialog(row, 'assessment')
                              }
                            >
                              <AssessmentIcon className={classes.actionIcon} />
                            </ActionIconButton>
                          </NormalTooltip>
                          <NormalTooltip title="Lesson report">
                            <ActionIconButton
                              onClick={() => this.handleLessonReport(row)}
                            >
                              <Icon className={classes.actionIcon}>
                                list_alt_icon
                              </Icon>
                            </ActionIconButton>
                          </NormalTooltip>
                        </Fragment>
                      )}
                    </div>
                  </CustomTableCell>
                )}
              </TableRow>
            ))}
          </TableBody>
        </Table>
        {this.state.redirect}
        <AttendanceDialog
          onRef={actualChild => (this.attendanceDialog = actualChild)}
        />
      </Paper>
    );
  }
}

MyClassPresentTable.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(stylesMyClassPresentTable)(MyClassPresentTable);

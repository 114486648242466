import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import {
  FormControl,
  Grid,
  MenuItem,
  Select,
  Typography
} from '@material-ui/core';
import authService from '../../../../api-authorization/AuthorizeService';
import { Loading } from '../../../../ui/Loading';
import { stylesCharting } from '../../../../class-course/reports/stylesCharting';
import { stylesAcademicInfo } from '../../../../academic-manage/stylesAcademicInfo';
import { PaymentInfoView } from './PaymentInfoView';
import { SquareChartView } from './SquareChartView';
import { PaymentChartView } from './PaymentChartView';
import classNames from 'classnames';

class PaymentStatistics extends Component {
  constructor(...args) {
    super(...args);
    this.state = {
      selectedYears: new Date().getFullYear(),
      loading: true
    };
  }

  componentDidMount() {
    this.populateCampusData();
  }

  populateCampusData = async () => {
    const { selectedYears } = this.state;
    const { campusInfo } = this.props;
    const token = await authService.getAccessToken();
    const [respCampusPaymentData] = await Promise.all([
      fetch(
        `api/CampusReport/GetCampusPaymnetStatistic/${campusInfo.id}/${selectedYears}`,
        {
          headers: !token ? {} : { Authorization: `Bearer ${token}` }
        }
      )
    ]);
    const [campusPaymentData] = await Promise.all([
      respCampusPaymentData.json()
    ]);

    const {
      totalPayment,
      remainPayment,
      totalRenews,
      totalPaymentStudents,
      totalRenewStudents,
      totalNewStudents,
      dataNumberOfRenews,
      months,
      monthPayments,
      monthRenews,
      monthNews,
      years
    } = this.prepareData(campusPaymentData);

    this.setState({
      totalPayment,
      remainPayment,
      totalRenews,
      totalPaymentStudents,
      totalRenewStudents,
      totalNewStudents,
      dataNumberOfRenews,
      monthPayments,
      monthRenews,
      monthNews,
      monthCategories: months,
      years: years,
      loading: false
    });
  };

  prepareData(campusPaymentData) {
    const totalPayment = campusPaymentData.studentPayments
      .map(item => item.totalPayment)
      .reduce((prev, curr) => prev + curr);
    const remainPayment = campusPaymentData.studentPayments
      .map(item => item.remainPayment)
      .reduce((prev, curr) => prev + curr);
    const totalRenews = campusPaymentData.studentPayments
      .map(item => item.totalRenews)
      .reduce((prev, curr) => prev + curr);
    const totalPaymentStudents = campusPaymentData.studentPayments
      .map(item => item.totalPaymentStudents)
      .reduce((prev, curr) => prev + curr);
    const totalRenewStudents = campusPaymentData.studentPayments
      .map(item => item.totalRenewStudents)
      .reduce((prev, curr) => prev + curr);
    const totalNewStudents = campusPaymentData.studentPayments
      .map(item => item.totalNewStudents)
      .reduce((prev, curr) => prev + curr);

    const renewsRatio = (
      (totalRenewStudents / totalPaymentStudents) *
      100
    ).toFixed(2);
    const dataNumberOfRenews = [
      {
        key: 'Ratio of renews',
        data: renewsRatio
      },
      {
        key: '',
        data: 100 - renewsRatio
      }
    ];
    const monthPayments = [];
    const monthRenews = [];
    const monthNews = [];
    campusPaymentData.monthPayments.forEach(element => {
      monthPayments.push(element.payment);
      monthRenews.push(element.renews);
      monthNews.push(element.news);
    });
    const months = campusPaymentData.months.map(item => {
      return `Tháng ${item}`;
    });
    const years = campusPaymentData.years.sort((a, b) => b - a);

    return {
      totalPayment,
      remainPayment,
      totalRenews,
      totalPaymentStudents,
      totalRenewStudents,
      totalNewStudents,
      dataNumberOfRenews,
      months,
      monthPayments,
      monthRenews,
      monthNews,
      years
    };
  }

  handleYearChange = event => {
    const selectedYears = event.target.value;
    this.setState({ selectedYears: selectedYears }, () => {
      this.populateCampusData();
    });
  };

  render() {
    const { loading } = this.state;
    let content = loading ? <Loading /> : this.renderUI();

    return <Fragment>{content}</Fragment>;
  }

  renderUI() {
    const { classes } = this.props;
    const {
      totalPayment,
      remainPayment,
      totalRenews,
      totalPaymentStudents,
      totalRenewStudents,
      totalNewStudents,
      dataNumberOfRenews,
      monthPayments,
      monthRenews,
      monthNews,
      monthCategories,
      years,
      selectedYears
    } = this.state;

    const paymentChartOptions = {
      chart: {
        type: 'bar',
        toolbar: {
          show: false
        },
        zoom: {
          enabled: false
        }
      },
      dataLabels: {
        enabled: false
      },
      xaxis: {
        categories: monthCategories
      },
      stroke: {
        show: true,
        width: 5,
        colors: ['transparent']
      },
      colors: ['#f2a129', '#6fa1f3', '#D91656']
    };

    const paymentChartSeries = [
      { name: 'Tổng phí Renew', data: monthRenews },
      { name: 'Tổng phí New', data: monthNews },
      { name: 'Tổng Payments', data: monthPayments }
    ];

    return (
      <Fragment>
        <Grid container className={classes.stdHeadline}>
          <div className={classes.stdSubtitle}>
            <Typography variant="h3" className={classes.sectionTitle}>
              {'Thông tin thống kê và báo cáo doanh thu của chi nhánh'}
            </Typography>
          </div>
          <div className={classes.stdSubtitleHr}>
            <span className={clsx(classes.stdLineCell)}></span>
          </div>
        </Grid>
        <FormControl
          className={classNames(
            classes.margin,
            classes.textField,
            classes.dropdownType
          )}
          style={{ width: 200, float: 'right', marginRight: '50px' }}
        >
          <Select value={selectedYears} onChange={this.handleYearChange}>
            <MenuItem>
              <em>Chọn năm</em>
            </MenuItem>
            {years.map((year, index) => (
              <MenuItem key={index} value={year}>
                {year}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <Grid container className={classes.stdBox} spacing={2}>
          <Grid item xs={3} container direction="column" justify="flex-start">
            <div className={classes.chartBoxTopPad}>
              <PaymentInfoView
                title="Total of Payments"
                subtitle="Tổng doanh thu"
                numberValue={totalPayment}
                classes={classes}
              />
            </div>
            <div className={classes.chartBoxTopPad}>
              <PaymentInfoView
                title="Total of Remaning Payments"
                subtitle="Tổng học phí còn lại"
                numberValue={remainPayment}
                classes={classes}
              />
            </div>
            <div className={classes.chartBoxTopPad}>
              <SquareChartView
                title="Total of Renews"
                subtitle="Tổng chi phí renews"
                numberValue={totalRenews}
                chartTitle="Tỷ lệ phần trăm học sinh renews"
                chartData={dataNumberOfRenews}
                classes={classes}
              />
            </div>
          </Grid>

          <Grid item xs={9} container direction="column" justify="flex-start">
            <div className={clsx(classes.chartBoxRoot, classes.chartBoxTopPad)}>
              <Grid
                container
                item
                xs={12}
                spacing={2}
                justifyContent="space-between"
              >
                <Grid item xs={4}>
                  <PaymentInfoView
                    title="Total Students Payments"
                    subtitle="Tổng học sinh Payments"
                    numberValue={totalPaymentStudents}
                    classes={classes}
                  />
                </Grid>
                <Grid item xs={4}>
                  <PaymentInfoView
                    title="Total Students Renew"
                    subtitle="Tổng học sinh renew"
                    numberValue={totalRenewStudents}
                    classes={classes}
                  />
                </Grid>
                <Grid item xs={4}>
                  <PaymentInfoView
                    title="Total Students New"
                    subtitle="Tổng học sinh new"
                    numberValue={totalNewStudents}
                    classes={classes}
                  />
                </Grid>
              </Grid>
              <Grid item xs={12}>
                <PaymentChartView
                  title="Payments/Renews in Month"
                  subtitle="Biểu đồ chi phí renew và payments trong tháng"
                  chartOptions={paymentChartOptions}
                  chartSeries={paymentChartSeries}
                  numCategories={12}
                  barWidth={60}
                  classes={classes}
                />
              </Grid>
            </div>
          </Grid>
        </Grid>
      </Fragment>
    );
  }
}

const stylesStudentStatistics = theme => ({
  ...stylesCharting(theme),
  ...stylesAcademicInfo(theme)
});

PaymentStatistics.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(stylesStudentStatistics)(PaymentStatistics);

import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import {
  Card,
  CardActions,
  CardContent,
  Collapse,
  Grid,
  IconButton,
  Typography
} from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { Loading } from '../../ui/Loading';
import { examTypes, stylesExamResults } from './stylesExamResults';
import { HtmlUtils } from '../../common/utils/HtmlUtils';
import { ExamResultUtils } from '../../common/ExamResultUtils';
import { fetchHelper } from '../../../helpers/fetch-helper';

class ExamResults extends Component {
  constructor(...args) {
    super(...args);
    this.state = {
      loading: true
    };
  }

  componentDidMount() {
    this.populateStudentData();
  }

  populateStudentData = async () => {
    this.getExamResults().then(() => this.setState({ loading: false }));
  };

  getExamResults = async () => {
    const { student, stdClass } = this.props;
    const examResultForms = await fetchHelper.get(
      `api/AcademicManage/GetExamResults/${student.id}/${stdClass.classId}`
    );

    const examResults = examResultForms.map(item => {
      const examResult = JSON.parse(item.examResultJson);
      const { sumScore, sumWeight } = examResult.academic.reduce(
        (acc, e) => {
          acc.sumScore += parseFloat(e.score);
          acc.sumWeight += parseFloat(e.weight);
          return acc;
        },
        { sumScore: 0.0, sumWeight: 0.0 }
      );

      const result = (sumScore / sumWeight) * 100;
      const score = result.toFixed(2);
      const final = ExamResultUtils.calcExamResult(score);

      return { ...item, examResult, score, final };
    });

    let examResultsExpanded = Array(examResults.length).fill(false);
    examResultsExpanded[0] = true;

    this.setState({
      examResults,
      examResultsExpanded
    });
  };

  handleExpandClick = idx => {
    this.setState(prevState => ({
      examResultsExpanded: {
        ...prevState.examResultsExpanded,
        [idx]: !prevState.examResultsExpanded[idx]
      }
    }));
  };

  render() {
    const { classesBase, classes } = this.props;
    const { examResults, examResultsExpanded, loading } = this.state;

    return (
      <Fragment>
        {loading ? (
          <Loading />
        ) : (
          <Grid
            container
            alignItems="flex-start"
            spacing={3}
            className={classesBase.stdBox}
          >
            <Grid item xs={12}>
              <Typography variant="h3" className={classesBase.stdBoxLabel}>
                Báo cáo thông tin đánh giá theo kỳ
              </Typography>
            </Grid>
            {examResults.map((examResult, idx) => {
              const formattedDate = new Date(
                examResult.createdDate
              ).toLocaleDateString();
              return (
                <Grid item xs={12} key={idx}>
                  <Card
                    className={clsx(classesBase.stdReportBox, classes.root)}
                  >
                    <CardActions disableSpacing>
                      <Typography
                        variant="h3"
                        className={clsx(
                          classes.titleText,
                          classesBase.sectionTitle
                        )}
                      >
                        {`${examResult.examResult.examName} (${formattedDate})`}
                      </Typography>
                      <div className={classes.floatRightBar}>
                        <Typography
                          variant="h3"
                          className={clsx(
                            classes.titleText,
                            classesBase.sectionTitle
                          )}
                        >
                          {examResult.final}
                        </Typography>
                        <IconButton
                          className={clsx(classes.expand, {
                            [classes.expandOpen]: examResultsExpanded[idx]
                          })}
                          onClick={() => {
                            this.handleExpandClick(idx);
                          }}
                        >
                          <ExpandMoreIcon />
                        </IconButton>
                      </div>
                    </CardActions>
                    <Collapse
                      in={examResultsExpanded[idx]}
                      timeout="auto"
                      unmountOnExit
                    >
                      <CardContent>
                        <Grid container spacing={2}>
                          <Grid item xs={2}>
                            <Typography
                              variant="h3"
                              className={clsx(
                                classes.inlineText,
                                classesBase.stdBoxLabel
                              )}
                            >
                              OVERALL PERFORMANCE
                            </Typography>
                          </Grid>
                          <Grid container item xs={10}>
                            {examResult.examResult.overall.map(
                              (criteria, idx) => (
                                <Grid item xs={2} key={idx}>
                                  <Typography
                                    variant="h3"
                                    className={clsx(
                                      classes.inlineText,
                                      classesBase.stdBoxLabel
                                    )}
                                  >
                                    {criteria.name}
                                  </Typography>
                                  <Typography
                                    variant="body2"
                                    className={classes.inlineText}
                                  >
                                    {criteria.evaluation}
                                  </Typography>
                                </Grid>
                              )
                            )}
                          </Grid>

                          <Grid item xs={2}>
                            <Typography
                              variant="h3"
                              className={clsx(
                                classes.inlineText,
                                classesBase.stdBoxLabel
                              )}
                            >
                              ACADEMIC PERFORMANCE
                            </Typography>
                          </Grid>
                          <Grid container item xs={10}>
                            {examResult.examResult.academic.map(
                              (criteria, idx) => (
                                <Grid item xs={2} key={idx}>
                                  <Typography
                                    variant="h3"
                                    className={clsx(
                                      classes.inlineText,
                                      classesBase.stdBoxLabel
                                    )}
                                  >
                                    {criteria.name}
                                  </Typography>
                                  <Typography
                                    variant="body2"
                                    className={classes.inlineText}
                                  >
                                    {criteria.score}/{criteria.weight}
                                  </Typography>
                                </Grid>
                              )
                            )}
                          </Grid>
                          <Grid item xs={2}></Grid>
                          <Grid container item xs={10}>
                            <Grid item xs={2}>
                              <Typography
                                variant="h3"
                                className={clsx(
                                  classes.inlineText,
                                  classesBase.stdBoxLabel
                                )}
                              >
                                Total
                              </Typography>
                              <Typography
                                variant="body2"
                                className={classes.inlineText}
                              >
                                {examResult.score}%
                              </Typography>
                            </Grid>
                            <Grid item xs={2}>
                              <Typography
                                variant="h3"
                                className={clsx(
                                  classes.inlineText,
                                  classesBase.stdBoxLabel
                                )}
                              >
                                Result
                              </Typography>
                              <Typography
                                variant="body2"
                                className={classes.inlineText}
                              >
                                {examResult.final}
                              </Typography>
                            </Grid>
                          </Grid>
                          <Grid item xs={2}>
                            <Typography
                              variant="h3"
                              className={clsx(
                                classes.inlineText,
                                classesBase.stdBoxLabel
                              )}
                            >
                              Teacher's comment:
                            </Typography>
                          </Grid>
                          <Grid container item xs={10}>
                            <Typography
                              variant="body2"
                              className={classes.inlineText}
                              dangerouslySetInnerHTML={HtmlUtils.createMarkup(
                                HtmlUtils.newlineToHtml(
                                  examResult.teacherComment
                                )
                              )}
                            />
                          </Grid>
                        </Grid>
                      </CardContent>
                    </Collapse>
                  </Card>
                </Grid>
              );
            })}
          </Grid>
        )}
      </Fragment>
    );
  }
}

ExamResults.propTypes = {
  classes: PropTypes.object.isRequired,
  student: PropTypes.object,
  stdClass: PropTypes.object,
  classesBase: PropTypes.object
};

export default withStyles(stylesExamResults)(ExamResults);

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle
} from '@material-ui/core';
import React, { Component } from 'react';

import ExamResult from './ExamResult';
import PropTypes from 'prop-types';
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';
import { withStyles } from '@material-ui/core/styles';
import { Schedule } from '@material-ui/icons';

const stylesExamResultDialog = () => ({
  root: {
    '& .MuiPaper-root': {
      width: '70%'
    }
  },
  dialogContent: {
    overflowY: 'unset'
  },
  pdfBtn: {
    color: 'coral'
  }
});

class ExamResultDialog extends Component {
  constructor(...args) {
    super(...args);
    this.examResult = React.createRef();
    this.state = {
      open: false,
      classInfo: {},
      title: 'Exam Result'
    };
  }

  componentDidMount() {
    this.props.onRef(this);
  }

  openExamResult(classInfo, studentInfo) {
    this.setState({ classInfo, studentInfo });
    this.openDialog();
  }
  saveExamResult = async () => {
    await this.examResult.saveExamResult();
    this.closeDialog();
  };

  calcExamResult(scoreTotal) {
    const result =
      scoreTotal < 60.0
        ? 'AVERAGE'
        : scoreTotal < 70.0
        ? 'FAIR'
        : scoreTotal < 80.0
        ? 'GOOD'
        : scoreTotal < 95.0
        ? 'VERY GOOD'
        : 'EXCELLENT';
    return result;
  }

  postExamResult = async () => {
    const { state, props } = this.examResult || {};
    const { examResult, total } = state || {};
    const { classInfo, studentInfo } = props || {};
    const { schedule, classTeachers = [], name: className } = classInfo || {};
    const { startDate } = schedule || {};
    const { examResultForm, teacherComment } = examResult || {};
    const templateId =
      'a18aceb2679949814cce6c2355021249722b4908928cde65af95edff2f987bdd';
    const formattedDate = new Date(startDate).toLocaleDateString();

    const findTeacher = isPrimary =>
      classTeachers.find(item => item.isPrimary === isPrimary)?.teacher
        .userInfo || {};

    const formatTeacherName = ({ fullName, englishName }) =>
      [fullName, englishName]
        .filter(Boolean)
        .join(' ')
        .trim();

    const mainTeacher = formatTeacherName(findTeacher(true));
    const coTeacher = formatTeacherName(findTeacher(false));

    const data = {
      data: {
        examName: examResultForm?.examName,
        studentName: `${studentInfo?.studentName} - ${studentInfo?.englishName}`,
        class: className,
        createDate: formattedDate,
        mainTeacher,
        coTeacher,
        total: total ?? 0,
        result: this.calcExamResult(total ?? 0),
        teacherComment: teacherComment ?? '',
        overall:
          examResultForm?.overall?.map(({ name, evaluation }) => ({
            name,
            evaluation
          })) ?? [],
        academic:
          examResultForm?.academic?.map(({ name, weight, score }) => ({
            name,
            weight,
            score
          })) ?? []
      }
    };

    try {
      const renderId = await this.sendRequest(data, templateId);
      if (renderId) {
        await this.downloadFile(renderId);
      }
    } catch (error) {
      console.log('Error API: ', error);
    }
  };

  sendRequest = async (data, templateId) => {
    const response = await fetch(
      `https://docgen.gee-o.edu.vn/render/${templateId}`,
      {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(data)
      }
    );
    if (!response.ok) {
      return null;
    }
    const result = await response.json();
    return result.data.renderId;
  };

  downloadFile = async renderId => {
    const fileResponse = await fetch(
      `https://docgen.gee-o.edu.vn/render/${renderId}`
    );
    if (fileResponse) {
      const blob = await fileResponse.blob();
      const downloadUrl = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = downloadUrl;
      const englishName = this.examResult?.props?.studentInfo?.englishName;
      const examName = this.examResult?.state?.examResult?.examResultForm
        ?.examName;
      a.download = `${englishName}_${examName}.docx`;
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
    }
  };

  downloadPdf = () => {
    const doc = document.getElementById('pdfContent');
    const contentElm = doc.getElementsByClassName('MuiPaper-root')[0];
    contentElm.style.boxShadow = 'unset';

    const input = document.getElementsByClassName('MuiDialog-paper')[0];
    const actionElm = input.getElementsByClassName('MuiDialogActions-root')[0];
    actionElm.style.visibility = 'hidden';

    html2canvas(input).then(canvas => {
      const imgData = canvas.toDataURL('image/png');
      var pdf = new jsPDF('landscape', 'px', 'a4');
      const imgProps = pdf.getImageProperties(imgData);
      const pdfWidth = pdf.internal.pageSize.getWidth();
      const pdfHeight = (imgProps.height * pdfWidth) / imgProps.width;
      pdf.addImage(imgData, 'PNG', 0, 0, pdfWidth, pdfHeight);
      const d = new Date();
      pdf.save(
        `Exam - ${this.state.studentInfo?.studentName.toUpperCase()}_Exam_Result_${
          this.examResult.state.examTemplates.filter(
            x => x.examType === this.examResult.state.examResult.examType
          )[0]?.examName
        }_${d.toLocaleDateString()}.pdf`
      );

      actionElm.style.visibility = 'visible';
    });
  };

  openDialog = () => {
    this.setState({ open: true });
  };
  closeDialog = () => {
    this.setState({ open: false });
  };

  render() {
    const { classes } = this.props;
    const { title, classInfo, studentInfo } = this.state;

    return (
      <Dialog
        open={this.state.open}
        onClose={this.closeDialog}
        maxWidth="xl"
        className={classes.root}
        id="pdfContent"
      >
        <DialogTitle>{title}</DialogTitle>
        <DialogContent className={classes.dialogContent}>
          <ExamResult
            classInfo={classInfo}
            studentInfo={studentInfo}
            onRef={actualChild => (this.examResult = actualChild)}
          />
        </DialogContent>
        <DialogActions>
          <Button
            onClick={this.downloadPdf}
            color="default"
            className={classes.pdfBtn}
          >
            Download PDF
          </Button>
          <Button onClick={this.postExamResult} color="inherit">
            Load Report
          </Button>
          <Button onClick={this.saveExamResult} color="secondary">
            Accept
          </Button>
          <Button onClick={this.closeDialog} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
}

ExamResultDialog.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(stylesExamResultDialog)(ExamResultDialog);

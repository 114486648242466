import '../assessment/style.css';

import {
  FormControl,
  FormControlLabel,
  Grid,
  Paper,
  Radio,
  TextField,
  Typography
} from '@material-ui/core';
import {
  MessageBarText,
  MessageBarVariants,
  MessageBars,
  MessageType
} from '../ui/popup/Messages';
import React, { Component, Fragment } from 'react';

import { Loading } from '../ui/Loading';
import PropTypes from 'prop-types';
import authService from '../api-authorization/AuthorizeService';
import { stylesAttendance } from './styleAttendance';
import { withStyles } from '@material-ui/core/styles';
import { Tabs } from '@material-ui/core';
import { Tab } from '@material-ui/core';
import { TabPanel } from './../ui/tab/TabPanel';
import WorkLog from './worklog/WorkLog';

class StudentAttendance extends Component {
  constructor(...args) {
    super(...args);
    this.state = {
      loading: true,
      tab: 0
    };
    this.messageBar = React.createRef();
  }

  componentDidMount() {
    this.props.onRef(this);
    this.populateSessionData();
  }

  populateSessionData = async () => {
    const { classId, logId, pageType } = this.props;
    const token = await authService.getAccessToken();
    const headers = !token ? {} : { Authorization: `Bearer ${token}` };
    const studentEndpoint =
      pageType === 'ClassListLog'
        ? 'GetStudentAttendanceByClass'
        : 'GetStudentByClass';
    const [
      respClassStudents,
      respLessonLog,
      respRatingStar
    ] = await Promise.all([
      fetch(`api/ClassStudents/${studentEndpoint}/${classId}`, {
        headers: headers
      }),
      fetch(`api/TeacherLessonLog/GetClassLessonInfo/${logId}`, {
        headers: headers
      }),
      fetch(`api/TeacherLessonLog/GetClassStudentReport/${logId}`, {
        headers: headers
      })
    ]);
    const [
      currentClassStudents,
      lessonLog,
      currentRatingStar
    ] = await Promise.all([
      respClassStudents.json(),
      respLessonLog.json(),
      respRatingStar.json()
    ]);
    this.setState(
      {
        currentClassStudents,
        lessonLog,
        currentRatingStar
      },
      this.populateReportData
    );
    this.props.updateStatus({
      ...lessonLog,
      lastSaved: new Date(lessonLog.logDateTime)
    });
  };

  populateReportData = () => {
    const { currentRatingStar, currentClassStudents } = this.state;
    const ratingMap = [];
    currentClassStudents.forEach(student => {
      ratingMap[student.subId] = { present: -1, note: '' };
    });
    if (currentRatingStar && currentRatingStar.length > 0) {
      currentRatingStar.forEach(student => {
        student.note = student.note ?? '';
        ratingMap[student.studentInfoId] = student;
      });
    }
    this.setState({ ratingMap, loading: false });
  };

  changePresenceStudent = async (studentId, attrName, event) => {
    const target = event.target;
    const attrValue =
      target.type === 'checkbox' ? target.checked : target.value;

    const { ratingMap } = this.state;
    ratingMap[studentId][attrName] =
      attrName === 'present' ? parseInt(attrValue) : attrValue;
    this.setState({ ratingMap });
    await this.saveAttendanceImmediately(studentId);
  };

  saveAttendanceImmediately = async studentId => {
    const token = await authService.getAccessToken();
    const headers = {
      ...{ Accept: 'application/json', 'Content-Type': 'application/json' },
      ...(!token ? {} : { Authorization: `Bearer ${token}` })
    };
    const { lessonLog } = this.state;
    this.saveAttendance(studentId, lessonLog.id, headers);
  };

  addStudentLessonLog = async (studentId, logId, headers) => {
    const studentLessonLogNew = {
      logId: logId,
      studentInfoId: studentId
    };
    const response = await fetch(
      `api/TeacherLessonLog/AddTodayStudentLessonLog`,
      {
        method: 'POST',
        body: JSON.stringify(studentLessonLogNew),
        headers: headers
      }
    );
    return response.ok ? await response.json() : null;
  };

  saveAttendance = async (studentId, logId, headers) => {
    const { ratingMap } = this.state;
    let studentLessonLog = await this.addStudentLessonLog(
      studentId,
      logId,
      headers
    );
    studentLessonLog.present = ratingMap[studentId].present;
    studentLessonLog.note = ratingMap[studentId].note;

    fetch(`api/TeacherLessonLog/SaveStudentLessonLog/${studentLessonLog.id}`, {
      method: 'PUT',
      body: JSON.stringify(studentLessonLog),
      headers: headers
    })
      .then(response => {
        if (!response.ok) {
          this.props.setSavingStatus(false);
          this.messageBar.showMessage(
            MessageBarVariants.Error,
            MessageType.Submit,
            MessageBarText.Error[MessageType.Submit]
          );
          throw new Error(response.status);
        }
        const { currentClassStudents, lessonLog } = this.state;
        this.numProcessStudent++;
        if (this.numProcessStudent === currentClassStudents.length) {
          this.props.updateStatus({ ...lessonLog, lastSaved: new Date() });
          this.props.setSavingStatus(false);
          this.messageBar.showMessage(
            MessageBarVariants.Success,
            MessageType.Submit,
            MessageBarText.Success[MessageType.Submit]
          );
        }
      })
      .catch(error => {
        console.error('Error:', error);
        this.props.setSavingStatus(false);
        this.messageBar.showMessage(
          MessageBarVariants.Error,
          MessageType.Submit,
          MessageBarText.Error[MessageType.Submit]
        );
      });

    return studentLessonLog;
  };

  saveStudentAttendance = async () => {
    this.props.setSavingStatus(true);
    const token = await authService.getAccessToken();
    const headers = {
      ...{ Accept: 'application/json', 'Content-Type': 'application/json' },
      ...(!token ? {} : { Authorization: `Bearer ${token}` })
    };

    const { currentClassStudents, lessonLog } = this.state;

    try {
      this.numProcessStudent = 0;
      currentClassStudents.forEach(student => {
        this.saveAttendance(student.subId, lessonLog.id, headers);
      });
    } catch (error) {
      console.error(error);
      this.messageBar.showMessage(
        MessageBarVariants.Error,
        MessageType.Submit,
        MessageBarText.Error[MessageType.Submit]
      );
    }
  };

  messageBarComp() {
    return (
      <MessageBars
        onRef={actualChild => (this.messageBar = actualChild)}
        variant={MessageBarVariants.Error}
        type={MessageType.Approve}
        message={'Cannot preview!'}
        handleClose={this.handleCloseMessageBar}
      />
    );
  }

  handleCloseMessageBar = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    this.messageBar.setOpen(false);
  };

  backgroundClassChange = value => {
    return `row-student ${(value === 1 && 'attended') ||
      (value === 0 && 'absent') ||
      (value === -1 && 'no-attendance')}`;
  };

  handleTabChange = (_, tab) => {
    this.setState({ tab });
  };

  render() {
    const { classes, classId, logId, classInfo } = this.props;
    const { currentClassStudents, ratingMap, loading, tab } = this.state;
    const content = loading ? (
      <Loading />
    ) : (
      <>
        <Paper className={classes.root}>
          <Tabs
            value={tab}
            onChange={this.handleTabChange}
            indicatorColor="primary"
            textColor="primary"
            variant="fullWidth"
            aria-label="full width tabs example"
          >
            <Tab label="Student Attendance" />
            <Tab label="Work log" />
          </Tabs>
          <TabPanel
            value={tab}
            index={0}
            component={
              <Paper className={classes.paper}>
                <Grid container direction="column" className={classes.root}>
                  <Grid item className={classes.contentCol}>
                    <Grid
                      item
                      xs
                      container
                      direction="column"
                      className={classes.presencePage}
                    >
                      <Grid item xs={12}>
                        {currentClassStudents.map(student => (
                          <div
                            className={
                              student.studentType === 4
                                ? 'row-student suspended'
                                : this.backgroundClassChange(
                                    ratingMap[student.subId].present
                                  )
                            }
                            key={student.subId}
                          >
                            <div className={classes.checkBox}>
                              <div className={classes.checkPresence}>
                                <FormControl>
                                  <FormControlLabel
                                    control={
                                      <Radio
                                        checked={
                                          ratingMap[student.subId].present === 1
                                        }
                                        onChange={evt =>
                                          this.changePresenceStudent(
                                            student.subId,
                                            'present',
                                            evt
                                          )
                                        }
                                        value={1}
                                      />
                                    }
                                    label="Có mặt"
                                  />
                                  <FormControlLabel
                                    control={
                                      <Radio
                                        checked={
                                          ratingMap[student.subId].present === 0
                                        }
                                        onChange={evt =>
                                          this.changePresenceStudent(
                                            student.subId,
                                            'present',
                                            evt
                                          )
                                        }
                                        value={0}
                                      />
                                    }
                                    label="Vắng mặt"
                                  />
                                  <FormControlLabel
                                    control={
                                      <Radio
                                        checked={
                                          ratingMap[student.subId].present ===
                                          -1
                                        }
                                        onChange={evt =>
                                          this.changePresenceStudent(
                                            student.subId,
                                            'present',
                                            evt
                                          )
                                        }
                                        value={-1}
                                      />
                                    }
                                    label="Không điểm danh"
                                  />
                                  <FormControlLabel
                                    control={
                                      <Radio
                                        checked={
                                          ratingMap[student.subId].present === 2
                                        }
                                        onChange={evt =>
                                          this.changePresenceStudent(
                                            student.subId,
                                            'present',
                                            evt
                                          )
                                        }
                                        value={2}
                                      />
                                    }
                                    label="Catchup"
                                  />
                                  <FormControlLabel
                                    control={
                                      <Radio
                                        checked={
                                          ratingMap[student.subId].present === 3
                                        }
                                        onChange={evt =>
                                          this.changePresenceStudent(
                                            student.subId,
                                            'present',
                                            evt
                                          )
                                        }
                                        value={3}
                                      />
                                    }
                                    label="Demo"
                                  />
                                </FormControl>
                              </div>
                            </div>
                            <label
                              htmlFor={'check-presence-' + student.subId}
                              className={classes.checkLabel}
                            >
                              <div className="left-side id-tab">
                                <div className="name-id">
                                  <Typography
                                    variant="h1"
                                    className={classes.studentName}
                                  >
                                    {student.englishName &&
                                      `${student.englishName} - `}
                                    {student.studentName}
                                  </Typography>
                                </div>
                              </div>
                            </label>
                            <div className={classes.noteCol}>
                              <div className={classes.noteField}>
                                <TextField
                                  variant="outlined"
                                  multiline
                                  rows="3"
                                  value={ratingMap[student.subId].note}
                                  onChange={evt =>
                                    this.changePresenceStudent(
                                      student.subId,
                                      'note',
                                      evt
                                    )
                                  }
                                  className={classes.noteText}
                                />
                              </div>
                            </div>
                          </div>
                        ))}
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Paper>
            }
          ></TabPanel>
          <TabPanel
            value={tab}
            index={1}
            component={
              <WorkLog classId={classId} logId={logId} classInfo={classInfo} />
            }
          ></TabPanel>
        </Paper>
      </>
    );

    return (
      <Fragment>
        {content}
        {this.messageBarComp()}
      </Fragment>
    );
  }
}

StudentAttendance.propTypes = {
  classes: PropTypes.object.isRequired,
  classId: PropTypes.string,
  logId: PropTypes.string,
  onRef: PropTypes.func,
  classInfo: PropTypes.object,
  pageType: PropTypes.string
};

export default withStyles(stylesAttendance)(StudentAttendance);

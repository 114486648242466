import {
  Checkbox,
  Divider,
  FormControl,
  FormControlLabel,
  Grid,
  Paper,
  TextField,
  Button
} from '@material-ui/core';
import React, { useEffect, useState } from 'react';

import PropTypes from 'prop-types';
import WorkLogResponsible from './WorkLogResponsible';
import WorkLogSubstitute from './WorkLogSubstitute';
import { fetchHelper } from '../../../helpers/fetch-helper';
import { stylesAttendance } from '../styleAttendance';
import { withStyles } from '@material-ui/core/styles';
import { useSnackbar } from '../../snackbar';
import CircularProgress from '@material-ui/core/CircularProgress';

const EXPAT_OPTIONS = ['full', 'expat_half'];
const EXCLUDE_MAIN_OPTIONS = ['off'];
const SHOW_EXPAT_CHECK_OPTIONS = ['halft', 'ct_half', 'mt_half', 'mf_half'];

const DEFAULT_WORK_LOG = {
  teacherId: '',
  subsForTeacherId: '',
  teacherLessonLogId: '',
  effortType: '',
  score: '0',
  isExpat: false,
  isEvent: false,
  evaluate: false,
  comment: ''
};

const WorkLog = ({ classId, classes, logId, classInfo }) => {
  const [teachers, setTeachers] = useState([]);
  const [classTeachers, setClassTeachers] = useState([]);
  const [shiftOptions, setShiftOptions] = useState([]);
  const [workLogsAudit, setWorkLogsHistoryAudit] = useState([]);
  const [workLogs, setWorkLogs] = useState([]);
  const [classCourse, setClassCourse] = useState(null);
  const [editing, setEditing] = useState(false);
  const [disabled, setDisabled] = useState(false);
  const [preventEdit, setPreventEdit] = useState(false);
  const [loading, setLoading] = useState(false);

  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    init();
  }, []);

  useEffect(() => {
    setDisabled(false);
  }, [editing]);

  useEffect(() => {
    allowEditCheck();
  }, [workLogsAudit]);

  const init = async () => {
    await getByLogId();

    await getTeachers();
    await getShiftTypeOptions();
  };

  const getClassTeacher = async () => {
    const res = await fetchHelper.get(`api/ClassTeacher/${classId}`);
    if (res.length > 0) {
      setClassTeachers(res);
    }
    return res;
  };

  const getTeachers = async () => {
    fetchHelper
      .get('api/AspNetUsers/teacher')
      .then(teachers =>
        setTeachers(
          teachers.filter(
            x => !classTeachers.map(x => x.teacherId).includes(x.id)
          )
        )
      );
  };

  const getShiftTypeOptions = async () => {
    const res = await fetchHelper.get(`api/worklog/shift-options/${classId}`);
    if (res.length > 0) {
      setShiftOptions(res);
    }
  };

  const getClass = async () => {
    const res = await fetchHelper.get(`api/classcourses/${classId}`);
    setClassCourse(res);
  };

  const updateWorkLogs = newWorkLogs => {
    setWorkLogs(newWorkLogs);
  };

  const allowEditCheck = () => {
    const [day, month, year] = classInfo.lessonDate.split('/').map(Number);
    // Get the earliest createdDate
    const earliestDate = new Date(year, month - 1, day);
    const currentDate = new Date();
    const daysDifference = Math.floor(
      (currentDate - earliestDate) / (1000 * 60 * 60 * 24)
    );

    // Hide the button if 7 days or more have passed since the earliest createdDate
    // if (daysDifference >= 7) {
    //   setPreventEdit(true);
    // }
  };

  const onEffortTypeChange = async workLog => {
    const filteredWorkLogs =
      workLog.effortType !== 'off'
        ? workLogs.filter(log => log.subsForTeacherId !== workLog.teacherId)
        : workLogs;

    const updatedWorkLogs = filteredWorkLogs.map(log => {
      if (log.teacherId === workLog.teacherId) {
        return {
          ...log,
          effortType: workLog.effortType,
          score: workLog.score,
          isEvent: workLog.effortType === 'off' ? false : workLog.isEvent
        };
      } else if (workLog.effortType === 'alone' && !log.subsForTeacherId) {
        return { ...log, effortType: 'off', isEvent: false };
      }
      return log;
    });

    updateWorkLogs(updatedWorkLogs);
  };

  const onSubstituteChange = async workLog => {
    updateWorkLogs([
      ...workLogs.filter(x => x.subsForTeacherId !== workLog.subsForTeacherId),
      workLog
    ]);
  };

  const onExpatSubsChange = async workLog => {
    updateWorkLogs([...workLogs.filter(x => !x.isExpat), workLog]);
  };

  const onExpatCheckboxChange = async e => {
    const expat = workLogs.find(x => x.isExpat);
    const _initWorklog = {
      ...DEFAULT_WORK_LOG,
      effortType: '',
      teacherLessonLogId: logId,
      isExpat: true
    };
    e.target.checked && !expat
      ? updateWorkLogs([...workLogs.filter(x => !x.isExpat), _initWorklog])
      : updateWorkLogs([...workLogs.filter(x => !x.isExpat)]);
  };

  const onEventChange = worklog => {
    updateWorkLogs(prevWorkLogs =>
      prevWorkLogs.map(log => {
        if (log.id === worklog.id) {
          return { ...worklog };
        }
        return log;
      })
    );
  };

  const onExpatEvaluateChange = async e => {
    const expat = workLogs.find(x => x.isExpat);
    const attrName = e.target.name;
    const attrValue =
      e.target.type === 'checkbox' ? e.target.checked : e.target.value;
    updateWorkLogs([
      ...workLogs.filter(x => !x.isExpat),
      { ...expat, [attrName]: attrValue }
    ]);
  };

  const doCreate = async object => {
    const rs = await fetchHelper.post(`api/worklog`, object);
    return rs.body;
  };

  const doUpdate = async object => {
    return await fetchHelper.put(`api/worklog`, object);
  };

  const doDelete = async id => {
    await fetchHelper.delete(`api/worklog/${id}`);
  };

  const getByLogId = async () => {
    try {
      const ct = await getClassTeacher();
      const rs = await fetchHelper.get(`api/WorkLog/GetByLogId/${logId}`);
      const wlogs = rs.body;

      setWorkLogsHistoryAudit(wlogs);
      const falseValue = () => {
        updateWorkLogs(wlogs);
        setDisabled(true);
      };
      wlogs.length === 0 ? setDefaultWorkLog(ct) : falseValue();
    } catch (error) {
      console.error('Error in getByLogId:', error);
    }
  };

  const setDefaultWorkLog = () => {
    getClassTeacher().then(ct => {
      updateWorkLogs(
        ct.map(x => ({
          ...DEFAULT_WORK_LOG,
          teacherId: x.teacherId,
          teacherLessonLogId: logId
        }))
      );
    });

    setEditing(true);
  };

  const getOptionByTeacherRole = worklog => {
    return classTeachers.find(x => x.teacherId === worklog.teacherId)?.isPrimary
      ? shiftOptions.filter(
          x =>
            !EXCLUDE_MAIN_OPTIONS.includes(x.value) &&
            !EXPAT_OPTIONS.includes(x.value) &&
            !['co_teacher'].includes(x.value)
        )
      : shiftOptions.filter(
          x =>
            !EXCLUDE_MAIN_OPTIONS.includes(x.value) &&
            !EXPAT_OPTIONS.includes(x.value) &&
            !['main_teacher', 'main_full'].includes(x.value)
        );
  };

  const cancel = () => {
    init();
    setEditing(false);
  };

  const refreshData = async () => {
    await init();
    setEditing(false);
  };

  const submitWorklog = async () => {
    try {
      setLoading(true);
      const workLogIds = new Set(workLogs.filter(w => w.id).map(w => w.id));
      const itemsToDelete = workLogsAudit.filter(
        item => !workLogIds.has(item.id)
      );

      const deletePromises = itemsToDelete.map(item => doDelete(item.id));
      const processedKeys = new Set();

      const workLogPromises = workLogs.map((w) => {
        if (w?.id) {
          return doUpdate(w);
        } else {
          const key = `${w.teacherId}-${w.effortType}-${w.subsForTeacherId}`;

          // Check if the key is already processed to prevent duplicate creation
          if (processedKeys.has(key)) {
            return;
          }

          // Mark this key as processed
          processedKeys.add(key);
          return doCreate(w);
        }
      });

      await Promise.all([...deletePromises, ...workLogPromises]);


      enqueueSnackbar('Processed successfully');
      await refreshData();
    } catch (error) {
      enqueueSnackbar('An error occurred while processing work logs');
      console.error('An error occurred while processing work logs:', error);
    } finally {
      setLoading(false);
    }
  };

  // const deleteWorkLog = async (id) => {
  //   try {
  //     await doDelete(id);
  //     setWorkLogs(workLogs.filter((log) => log.id !== id)); // Update the workLogs state
  //     enqueueSnackbar('Work log deleted successfully');
  //   } catch (error) {
  //     enqueueSnackbar('An error occurred while deleting the work log');
  //     console.error('An error occurred while deleting the work log:', error);
  //   }
  // };

  return (
    <Paper
      className={classes?.paper}
      style={{ padding: '1.5rem', minHeight: 380 }}
    >
      {!preventEdit && (
        <Grid container justifyContent="flex-end">
          {disabled && (
            <Button
              variant="contained"
              color="primary"
              className={classes.button}
              onClick={() => setEditing(true)}
            >
              Edit
            </Button>
          )}
          {editing && (
            <Button
              variant="contained"
              color="default"
              className={classes.button}
              onClick={cancel}
            >
              Cancel
            </Button>
          )}
          {loading ? (
            <Button
              variant="contained"
              color="default"
              className={classes.button}
            >
              <CircularProgress size={20} />
            </Button>
          ) : (
            <Button
              variant="contained"
              color="secondary"
              className={classes.button}
              onClick={submitWorklog}
              disabled={!workLogs.some(x => x.effortType) || !editing}
            >
              Save Worklog
            </Button>
          )}
        </Grid>
      )}
      <div style={{ height: '100%', display: 'flex', gap: '1rem' }}>
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            gap: '2rem',
            width: '50%',
            padding: '1rem'
          }}
        >
          <div>Teachers of Class</div>
          {workLogs
            .filter(x => x.subsForTeacherId === '' && !x.isExpat)
            .map((w, index) => (
              <div
                key={index}
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1)',
                  padding: '1rem 0',
                  width: '100%'
                }}
              >
                <WorkLogResponsible
                  options={
                    workLogs.filter(
                      x =>
                        x.effortType === 'alone' &&
                        x.id !== w.id &&
                        x.subsForTeacherId === ''
                    ).length === 0
                      ? shiftOptions.filter(
                          x => !EXPAT_OPTIONS.includes(x.value)
                        )
                      : shiftOptions.filter(x => x.value === 'off')
                  }
                  teacher={
                    classTeachers.find(x => x.teacherId === w.teacherId) ||
                    teachers.find(x => x.id === w.teacherId)
                  }
                  workLog={w}
                  handleChange={onEffortTypeChange}
                  eventChange={onEventChange}
                  disabled={disabled}
                />
                {w.effortType === 'off' && (
                  <WorkLogSubstitute
                    options={getOptionByTeacherRole(w)}
                    teachers={teachers}
                    workLog={
                      workLogs.find(
                        x => x.subsForTeacherId === w.teacherId
                      ) || {
                        ...DEFAULT_WORK_LOG,
                        subsForTeacherId: w.teacherId,
                        effortType: '',
                        isEvent: false,
                        teacherLessonLogId: w.teacherLessonLogId
                      }
                    }
                    handleChange={onSubstituteChange}
                    disabled={disabled}
                  />
                )}
              </div>
            ))}
        </div>
        <Divider orientation="vertical" flexItem />
        <div>
          <FormControlLabel
            control={
              <Checkbox
                checked={workLogs.find(x => x.isExpat)?.isExpat || false}
                onChange={onExpatCheckboxChange}
              />
            }
            label="Expat"
            disabled={disabled}
          />
          {workLogs
            .filter(x => x.isExpat === true)
            .map(w => (
              <Grid item xs={12} key={w.teacherId}>
                <WorkLogSubstitute
                  options={shiftOptions.filter(x =>
                    EXPAT_OPTIONS.includes(x.value)
                  )}
                  teachers={teachers.filter(x => x.isExpat)}
                  workLog={w}
                  handleChange={onExpatSubsChange}
                  disabled={disabled}
                />
                <FormControl>
                  <FormControlLabel
                    control={
                      <Checkbox
                        name="evaluate"
                        checked={
                          workLogs.find(x => x.isExpat)?.evaluate || false
                        }
                        onChange={onExpatEvaluateChange}
                        disabled={disabled}
                      />
                    }
                    label="Evaluate the expat lesson"
                  />
                </FormControl>
                <FormControl style={{ width: '100%' }}>
                  <TextField
                    fullWidth
                    multiline
                    minRows="4"
                    variant="outlined"
                    InputLabelProps={{ shrink: true }}
                    placeholder="Enter notes"
                    name="comment"
                    value={workLogs.find(x => x.isExpat)?.comment || ''}
                    onChange={onExpatEvaluateChange}
                    disabled={disabled}
                  />
                </FormControl>
              </Grid>
            ))}
        </div>
      </div>
    </Paper>
  );
};

WorkLog.propTypes = {
  classes: PropTypes.object.isRequired,
  classId: PropTypes.string,
  logId: PropTypes.string,
  classInfo: PropTypes.object
};

export default withStyles(stylesAttendance)(WorkLog);

import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { Redirect } from 'react-router-dom';
import { withStyles } from '@material-ui/core/styles';
import authService from '../api-authorization/AuthorizeService';
import { Loading } from '../ui/Loading';
import CloseOutlinedIcon from '@material-ui/icons/CloseOutlined';
import Fab from '@material-ui/core/Fab';
import { MyClassPaths } from './MyClassConstants';
import { Paper } from '@material-ui/core';
import CommonDataTable from '../ui/table/CommonDataTable';
import ExamResultDialog from './views/ExamResultDialog';
import ClassCourseInfo from '../class-course/ClassCourseInfo';

const stylesListMyClass = theme => ({
  fab: {
    margin: theme.spacing(2, 1)
  }
});

class ListStudentInClassComp extends Component {
  static displayName = ListStudentInClassComp.name;

  constructor(...args) {
    super(...args);
    this.examResultDialog = React.createRef();
    this.state = {
      Data: [],
      loading: true,
      redirect: null,
      examResultDialog: (
        <ExamResultDialog
          onRef={actualChild => (this.examResultDialog = actualChild)}
        />
      )
    };
  }

  componentDidMount() {
    this.populateData();
  }

  async populateData() {
    const [token] = await Promise.all([authService.getAccessToken()]);
    const [respClassInfo, respData] = await Promise.all([
      fetch(`api/ClassCourses/${this.props.classId}`, {
        headers: !token ? {} : { Authorization: `Bearer ${token}` }
      }),
      fetch(
        `api/ClassStudents/GetStudentsByCompletedClass/${this.props.classId}`,
        {
          headers: !token ? {} : { Authorization: `Bearer ${token}` }
        }
      )
    ]);
    const [classInfo, data] = await Promise.all([
      respClassInfo.json(),
      respData.json()
    ]);
    data.forEach((element, idx) => {
      element.sortOrder = idx + 1;
    });
    this.setState({ Data: data, classInfo, loading: false });
  }

  handleBackToList = () => {
    this.redirectTo(MyClassPaths.Preview);
  };

  redirectTo = toUrl => {
    this.setState({
      redirect: <Redirect to={toUrl} push={true} />
    });
  };

  showExamResultDialog = async rowData => {
    const { classInfo } = this.state;
    this.examResultDialog.openExamResult(classInfo, rowData);
  };

  renderDatagrid() {
    const { classes } = this.props;
    const { classInfo, Data } = this.state;
    const cols = [
      { name: 'sortOrder', header: '#', width: '60', align: 'center' },
      {
        name: 'studentName',
        header: 'Name',
        align: 'left'
      },
      {
        name: 'englishName',
        header: 'English name',
        align: 'left'
      },
      { name: 'fatherName', header: 'Father Name', align: 'left' },
      { name: 'fatherPhone', header: 'Father Phone', align: 'left' },
      { name: 'motherName', header: 'Mother Name', align: 'left' },
      { name: 'motherPhone', header: 'Mother Phone', align: 'left' },
      { name: 'startTimeFormat', header: 'Start Time', align: 'left' },
      { name: 'endTimeFormat', header: 'End Time', align: 'left' },
      { name: 'classType', header: 'Type', align: 'left' }
    ];
    const actions = [
      {
        name: 'callback',
        callBackFn: this.showExamResultDialog,
        tooltip: 'Exam result',
        icon: 'assignment_turned_in'
      }
    ];

    return (
      <Fragment>
        <ClassCourseInfo classCourse={classInfo} />
        <Paper>
          <CommonDataTable
            onRef={actualChild => (this.child = actualChild)}
            rows={Data}
            cols={cols}
            actions={actions}
            isPaging={true}
          />
        </Paper>
        <Fab
          aria-label="Cancel"
          className={classes.fab}
          onClick={this.handleBackToList}
        >
          <CloseOutlinedIcon color="action" />
        </Fab>
      </Fragment>
    );
  }

  render() {
    let contents = this.state.loading ? <Loading /> : this.renderDatagrid();

    return (
      <Fragment>
        {contents}
        {this.state.redirect}
        {this.state.examResultDialog}
      </Fragment>
    );
  }
}

ListStudentInClassComp.propTypes = {
  classes: PropTypes.object.isRequired
};

export const ListStudentInClass = withStyles(stylesListMyClass)(
  ListStudentInClassComp
);

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle
} from '@material-ui/core';
import React, { Component } from 'react';

import PropTypes from 'prop-types';
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';
import { withStyles } from '@material-ui/core/styles';
import { Roles } from '../../common/constants/Roles';
import ExamResult from '../../my-class/views/ExamResult';

const stylesExamResultClassDialog = () => ({
  root: {
    '& .MuiPaper-root': {
      width: '70%'
    }
  },
  dialogContent: {
    overflowY: 'unset'
  },
  pdfBtn: {
    color: 'coral'
  }
});

class ExamResultClassDialog extends Component {
  constructor(...args) {
    super(...args);
    this.examResult = React.createRef();
    this.state = {
      open: false,
      classInfo: {},
      title: 'Exam Result'
    };
  }

  componentDidMount() {
    this.props.onRef(this);
  }

  openExamResult(classInfo, studentInfo) {
    this.setState({ classInfo, studentInfo });
    this.openDialog();
  }
  saveExamResult = async () => {
    await this.examResult.saveExamResult();
    this.closeDialog();
  };

  downloadPdf = () => {
    const doc = document.getElementById('pdfContent');
    const contentElm = doc.getElementsByClassName('MuiPaper-root')[0];
    contentElm.style.boxShadow = 'unset';

    const input = document.getElementsByClassName('MuiDialog-paper')[0];
    const actionElm = input.getElementsByClassName('MuiDialogActions-root')[0];
    actionElm.style.visibility = 'hidden';

    html2canvas(input).then(canvas => {
      const imgData = canvas.toDataURL('image/png');
      var pdf = new jsPDF('landscape', 'px', 'a4');
      const imgProps = pdf.getImageProperties(imgData);
      const pdfWidth = pdf.internal.pageSize.getWidth();
      const pdfHeight = (imgProps.height * pdfWidth) / imgProps.width;
      pdf.addImage(imgData, 'PNG', 0, 0, pdfWidth, pdfHeight);
      const d = new Date();
      pdf.save(
        `Exam - ${this.state.studentInfo?.studentName.toUpperCase()}_Exam_Result_${
          this.examResult.state.examTemplates.filter(
            x => x.examType === this.examResult.state.examResult.examType
          )[0]?.examName
        }_${d.toLocaleDateString()}.pdf`
      );

      actionElm.style.visibility = 'visible';
    });
  };

  openDialog = () => {
    this.setState({ open: true });
  };
  closeDialog = () => {
    this.setState({ open: false });
  };

  render() {
    const { classes, userRole } = this.props;
    const { title, classInfo, studentInfo } = this.state;

    return (
      <>
        {[Roles.Admin.toLowerCase(), Roles.AcadManager.toLowerCase()].includes(
          this.props.userRole
        ) ? (
          <Dialog
            open={this.state.open}
            onClose={this.closeDialog}
            maxWidth="xl"
            className={classes.root}
            id="pdfContent"
          >
            <DialogTitle>{title}</DialogTitle>
            <DialogContent className={classes.dialogContent}>
              <ExamResult
                classInfo={classInfo}
                studentInfo={studentInfo}
                onRef={actualChild => (this.examResult = actualChild)}
              />
            </DialogContent>
            <DialogActions>
              <Button
                onClick={this.downloadPdf}
                color="default"
                className={classes.pdfBtn}
              >
                Download PDF
              </Button>
              <Button onClick={this.saveExamResult} color="secondary">
                Accept
              </Button>
              <Button onClick={this.closeDialog} color="primary">
                Close
              </Button>
            </DialogActions>
          </Dialog>
        ) : (
          <Dialog
            open={this.state.open}
            onClose={this.closeDialog}
            maxWidth="xl"
            className={classes.root}
            id="pdfContent"
          >
            <DialogTitle>{title}</DialogTitle>
            <DialogContent className={classes.dialogContent}>
              <ExamResult
                classInfo={classInfo}
                studentInfo={studentInfo}
                onRef={actualChild => (this.examResult = actualChild)}
              />
            </DialogContent>
          </Dialog>
        )}
      </>
    );
  }
}

ExamResultClassDialog.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(stylesExamResultClassDialog)(ExamResultClassDialog);
